import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import StateCrossLinking from "./StateCrossLinking";
import { autoStatesList, homeStatesList, rentersStatesList, stateCrossLinkingExtendedData } from "./MockData.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "state-cross-linking"
    }}>{`State Cross Linking`}</h1>
    <h2 {...{
      "id": "attributes"
    }}>{`Attributes`}</h2>
    <Props of={StateCrossLinking} mdxType="Props" />
    <h2 {...{
      "id": "insurance-types"
    }}>{`Insurance Types`}</h2>
    <ul>
  <li>Auto</li>
  <li>Home</li>
  <li>Renters</li>
    </ul>
    <p>{`Structure defined for `}<strong parentName="p">{`states`}</strong>{` parameter:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const states = [
  { name: 'AL', url: '/insurance/auto/cheapest-car-insurance-alabama/' },
  { name: 'AK', url: '/insurance/auto/cheapest-car-insurance-alaska/' },
  { name: 'AZ', url: '/insurance/auto/cheapest-car-insurance-arizona/' },
  { name: 'AR', url: '/insurance/auto/cheapest-car-insurance-arkansas/' },
  { name: 'CA', url: '/insurance/auto/cheapest-car-insurance-california/' },
  { name: 'CO', url: '/insurance/auto/cheapest-car-insurance-colorado/' },
  { name: 'CT', url: '/insurance/auto/cheapest-car-insurance-connecticut/' },
  { name: 'DE', url: '/insurance/auto/cheapest-car-insurance-delaware/' },
  { name: 'FL', url: '/insurance/auto/cheapest-car-insurance-florida/' },
  { name: 'GA', url: '/insurance/auto/cheapest-car-insurance-georgia/' }
];
`}</code></pre>
    <h2 {...{
      "id": "auto-insurance-basic-usage"
    }}>{`Auto Insurance Basic Usage`}</h2>
    <Playground __position={2} __code={'<StateCrossLinking\n  title=\"Find Cheap Car Insurance by State\"\n  states={autoStatesList}\n  insurance=\"auto\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      StateCrossLinking,
      autoStatesList,
      homeStatesList,
      rentersStatesList,
      stateCrossLinkingExtendedData,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StateCrossLinking title="Find Cheap Car Insurance by State" states={autoStatesList} insurance="auto" mdxType="StateCrossLinking" />
    </Playground>
    <h2 {...{
      "id": "home-insurance-basic-usage"
    }}>{`Home Insurance Basic Usage`}</h2>
    <Playground __position={3} __code={'<StateCrossLinking\n  title=\"Find Cheap Home Insurance by State\"\n  states={homeStatesList}\n  insurance=\"home\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      StateCrossLinking,
      autoStatesList,
      homeStatesList,
      rentersStatesList,
      stateCrossLinkingExtendedData,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StateCrossLinking title="Find Cheap Home Insurance by State" states={homeStatesList} insurance="home" mdxType="StateCrossLinking" />
    </Playground>
    <h2 {...{
      "id": "renters-insurance-basic-usage"
    }}>{`Renters Insurance Basic Usage`}</h2>
    <Playground __position={4} __code={'<StateCrossLinking\n  title=\"Find Cheap Renters Insurance by State\"\n  states={rentersStatesList}\n  insurance=\"renters\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      StateCrossLinking,
      autoStatesList,
      homeStatesList,
      rentersStatesList,
      stateCrossLinkingExtendedData,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StateCrossLinking title="Find Cheap Renters Insurance by State" states={rentersStatesList} insurance="renters" mdxType="StateCrossLinking" />
    </Playground>
    <h2 {...{
      "id": "extended-version-basic-usage"
    }}>{`Extended version Basic Usage`}</h2>
    <p>{`Structure defined for `}<strong parentName="p">{`data`}</strong>{` parameter:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const data = [
  { name: 'AL', url: '/insurance/auto/cheapest-car-insurance-alabama/', company: 'State Farm', avgPremium='1200'},
  { name: 'AK', url: '/insurance/auto/cheapest-car-insurance-alaska/', company: 'State Farm', avgPremium='1200'},
  { name: 'AZ', url: '/insurance/auto/cheapest-car-insurance-arizona/', company: 'State Farm', avgPremium='1200'},
  { name: 'AR', url: '/insurance/auto/cheapest-car-insurance-arkansas/', company: 'State Farm', avgPremium='1200'},
  { name: 'CA', url: '/insurance/auto/cheapest-car-insurance-california/', company: 'State Farm', avgPremium='1200'},
  { name: 'CO', url: '/insurance/auto/cheapest-car-insurance-colorado/', company: 'State Farm', avgPremium='1200'},
  { name: 'CT', url: '/insurance/auto/cheapest-car-insurance-connecticut/', company: 'State Farm', avgPremium='1200'},
  { name: 'DE', url: '/insurance/auto/cheapest-car-insurance-delaware/', company: 'State Farm', avgPremium='1200'},
  { name: 'FL', url: '/insurance/auto/cheapest-car-insurance-florida/', company: 'State Farm', avgPremium='1200'},
  { name: 'GA', url: '/insurance/auto/cheapest-car-insurance-georgia/', company: 'State Farm', avgPremium='1200' }
];
`}</code></pre>
    <h3 {...{
      "id": "cheapest-and-largest"
    }}>{`Cheapest and Largest`}</h3>
    <Playground __position={5} __code={'<StateCrossLinking\n  extended=\"true\"\n  data={stateCrossLinkingExtendedData}\n  type=\"cheapest\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      StateCrossLinking,
      autoStatesList,
      homeStatesList,
      rentersStatesList,
      stateCrossLinkingExtendedData,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StateCrossLinking extended="true" data={stateCrossLinkingExtendedData} type="cheapest" mdxType="StateCrossLinking" />
    </Playground>
    <h3 {...{
      "id": "averages"
    }}>{`Averages`}</h3>
    <Playground __position={6} __code={'<StateCrossLinking\n  extended=\"true\"\n  data={stateCrossLinkingExtendedData}\n  type=\"averages\"\n  insurance=\"home\"\n/>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      StateCrossLinking,
      autoStatesList,
      homeStatesList,
      rentersStatesList,
      stateCrossLinkingExtendedData,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <StateCrossLinking extended="true" data={stateCrossLinkingExtendedData} type="averages" insurance='home' mdxType="StateCrossLinking" />
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      